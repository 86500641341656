import React from 'react';
import { FaClipboardList } from 'react-icons/fa';
import { useIntl, navigate } from 'gatsby-plugin-intl';

import { Container, Title, PortfolioButton } from './styles';

export default function Portfolio() {
  const intl = useIntl();

  const handleClick = () => {
    navigate('/portfolio');
  };

  return (
    <Container>
      <Title>
        <FaClipboardList />
        {intl.formatMessage({ id: 'pages.portfolio.title' })}
      </Title>

      <PortfolioButton onClick={handleClick}>
        {intl.formatMessage({ id: 'components.button.show' })}
      </PortfolioButton>
    </Container>
  );
}
