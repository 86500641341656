import React from 'react';
import { useIntl } from 'gatsby-plugin-intl';
import { FaInfoCircle } from 'react-icons/fa';

import { Container, Title, Description } from './styles';

export default function About() {
  const intl = useIntl();

  return (
    <Container>
      <Title>
        <FaInfoCircle />
        {intl.formatMessage({ id: 'pages.about.title' })}
      </Title>
      <Description>{intl.formatMessage({ id: 'pages.about.bio' })}</Description>
    </Container>
  );
}
