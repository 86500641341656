import styled from 'styled-components';

export const Container = styled.section`
  max-width: 420px;
  margin: 40px auto;
  padding: 0 20px;
  text-align: center;
`;

export const Title = styled.h2`
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 10px 0;
  svg {
    margin-right: 5px;
  }
`;

export const Item = styled.p`
  display: flex;
  justify-content: center;
  align-items: center;
  flex-wrap: wrap;
`;

export const Badge = styled.i`
  display: flex;
  justify-content: center;
  align-items: center;
  flex-wrap: wrap;
  margin: 5px 5px;

  padding: 0 2rem;
  border-radius: 12px;
  min-width: 110px;
  height: 24px;
  background: ${props => props.theme.color};
  color: ${props => props.theme.inverseColor};
  &:hover {
    background: ${props => props.theme.inverseColor};
    color: ${props => props.theme.color};
    cursor: pointer;
  }
`;
