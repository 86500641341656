import styled from 'styled-components';

export const Container = styled.section`
  max-width: 100%;
  width: 620px;
  margin: 40px auto;
  padding: 0 20px;
  text-align: center;
`;

export const Title = styled.h2`
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 10px 0;
  svg {
    margin-right: 5px;
  }
`;

export const Description = styled.p``;
