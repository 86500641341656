import React from 'react';
import { FaBriefcase } from 'react-icons/fa';
import { useIntl } from 'gatsby-plugin-intl';

import { Container, Title, Item } from './styles';

export default function Works() {
  const intl = useIntl();

  return (
    <Container>
      <Title>
        <FaBriefcase />
        {intl.formatMessage({ id: 'pages.works.title' })}
      </Title>

      <Item>• W3 Connect</Item>
      <Item>• Appi9</Item>
      <Item>• Casa Civil CE</Item>
      <Item>• Goowit</Item>
    </Container>
  );
}
