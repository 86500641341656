import React from 'react';
import { useIntl } from 'gatsby-plugin-intl';

import Image from '../../components/Image';

import {
  Container,
  Avatar,
  Title,
  Phone,
  Email,
  Language,
  Status,
  LangSelector,
  LangSelectorFlag,
} from './styles';

import brazilFlag from '../../assets/brazil.svg';
import japanFlag from '../../assets/japan.svg';
import usFlag from '../../assets/united-states.svg';
import spainFlag from '../../assets/spain.svg';

export default function Details() {
  const intl = useIntl();

  return (
    <Container>
      <Avatar>
        <Image
          filename="me.jpg"
          alt={intl.formatMessage({ id: 'pages.main.name' })}
        />
      </Avatar>

      <Title>{intl.formatMessage({ id: 'pages.main.name' })}</Title>
      <Phone>+81 070 4086 7148</Phone>
      <Email>programador@programador.me</Email>

      <Language>日本語 • English • Português • Español</Language>
      <Status>React is Poetry</Status>

      <LangSelector>
        <a href="/ja">
          <LangSelectorFlag src={japanFlag} alt="Japan" />
        </a>
        <a href="/pt">
          <LangSelectorFlag src={brazilFlag} alt="Brazil" />
        </a>
        <a href="/en">
          <LangSelectorFlag src={usFlag} alt="USA" />
        </a>
        <a href="/es">
          <LangSelectorFlag src={spainFlag} alt="Spain" />
        </a>
      </LangSelector>
    </Container>
  );
}
