import styled from 'styled-components';

export const Container = styled.section`
  max-width: 1300px;
  margin: 0 auto;
  padding: 0 20px;
  text-align: center;
`;

export const Avatar = styled.div`
  display: block;
  max-width: 120px;
  border-radius: 50%;
  overflow: hidden;
  border: 1px solid ${({ theme }) => theme.color};
  margin: 20px auto;
`;

export const Title = styled.h1`
  margin: 10px 0;
`;

export const Phone = styled.p`
  margin: 10px 0;
`;

export const Email = styled.p`
  margin: 10px 0;
`;

export const Language = styled.p`
  margin: 10px 0;
`;

export const Status = styled.p`
  margin: 10px 0;
`;

export const LangSelector = styled.div``;

export const LangSelectorFlag = styled.img`
  width: 32px;
  margin-left: 10px;
`;
