import React from 'react';
import { FaGraduationCap } from 'react-icons/fa';
import { useIntl } from 'gatsby-plugin-intl';

import { Container, Title, Item } from './styles';

export default function Education() {
  const intl = useIntl();

  return (
    <Container>
      <Title>
        <FaGraduationCap />
        {intl.formatMessage({ id: 'pages.education.title' })}
      </Title>

      <Item>JLPT (日本語能力試験) - N4</Item>
      <Item>前橋医療福祉専門学校 - Japanese Language</Item>
      <Item>RocketSeat - Ignite 2021</Item>
      <Item>Sistemas de Informação - Estácio FIC</Item>
      <Item>Engenharia de Computação - UNIFOR</Item>
      <Item>RocketSeat - GoStack 10</Item>
      <Item>English Live</Item>
      <Item>Programa Japonês Online</Item>
    </Container>
  );
}
