import React from 'react';

import SEO from '../components/Seo';
import Header from '../components/Header';
import Footer from '../components/Footer';

import Details from '../containers/Details';
import About from '../containers/About';
import Education from '../containers/Education';
import Works from '../containers/Works';
import Skills from '../containers/Skills';
import Portfolio from '../containers/Portfolio';

export default () => (
  <>
    <SEO title="Thiago Paz Developer 👨🏻‍🚀" />

    <Header />

    <Details />

    <About />

    <Education />

    <Portfolio />

    <Works />

    <Skills />

    <Footer>
      <p>前橋市、群馬県、日本, {new Date().getFullYear()} - Thiago da Paz</p>
    </Footer>
  </>
);
