import React from 'react';
import { FaRocket } from 'react-icons/fa';
import { useIntl } from 'gatsby-plugin-intl';

import { Container, Title, Item, Badge } from './styles';

export default function Skills() {
  const intl = useIntl();

  return (
    <Container>
      <Title>
        <FaRocket />
        {intl.formatMessage({ id: 'pages.skills.title' })}
      </Title>

      <Item>
        <Badge>Node JS</Badge>
        <Badge>React JS</Badge>
        <Badge>React Native</Badge>
        <Badge>Android</Badge>
        <Badge>GIT</Badge>
        <Badge>TDD</Badge>
        <Badge>API</Badge>
        <Badge>GraphQL</Badge>
      </Item>
    </Container>
  );
}
